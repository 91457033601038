import React from 'react';

const Complete = () => {
  return (
    <main className='main'>
      <div className='complete'>
        <div className='complete__thanks'>
          <p>Thank You for submitting your response.</p>
          {/* <br />
          <br />
          <p>Look forward to meeting you then!</p> */}
        </div>
      </div>
    </main>
  );
};

export default Complete;
