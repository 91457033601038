const surveyJSON = {
  pages: [
    {
      name: 'page1',
      elements: [
        {
          type: 'panel',
          name: 'panel1',
          elements: [
            {
              type: 'html',
              name: 'intro',
              hideNumber: true,
              html: '<h5>This questionnaire consists of a list of 32 statements describing various behaviors and 3 open-ended questions for capturing qualitative comments. Takes about 7-10 minutes to complete. All questions need to be responded to.<br><br>\nWhen you are done answering all of the questions, click “Complete” at the end of the page. The "thank you for your response" message indicates the successful submission of your response.<br><br> *****  </h5>',
            },
          ],
        },
        {
          type: 'text',
          name: 'name',
          title: 'Your Name',
          hideNumber: true,
          isRequired: true,
        },
        {
          type: 'text',
          name: 'email',
          title: 'Your Email-id',
          hideNumber: true,
          isRequired: true,
          inputType: 'email',
        },
        {
          type: 'text',
          name: 'otherName',
          title: 'Name of the person you are providing the feedback for.',
          hideNumber: true,
          isRequired: true,
        },
      ],
    },
    {
      name: 'page3',
      elements: [
        {
          type: 'matrix',
          name: 'other1',
          title:
            'Please indicate the level of consistency with which {otherName} demonstrates the following behaviors at the workplace. All statements need to be responded to.',
          hideNumber: true,
          columns: [
            {
              value: '1',
              text: 'Never',
            },
            {
              value: '2',
              text: 'Rarely',
            },
            {
              value: '3',
              text: 'Occasionally',
            },
            {
              value: '4',
              text: 'Frequently',
            },
            {
              value: '5',
              text: 'Always',
            },
            {
              value: '6',
              text: "Don't Know",
            },
          ],
          rows: [
            {
              value: 'O_1_1',
              text: 'puts organization interest before own',
            },
            {
              value: 'O_1_2',
              text: 'sets the team for success',
            },
            {
              value: 'O_1_3',
              text: 'uses the team resources and time wisely',
            },
            {
              value: 'O_1_4',
              text: 'demonstrates ownership and clear accountability',
            },
            {
              value: 'O_1_5',
              text: 'articulates the purpose and vision for the future',
            },
            {
              value: 'O_2_1',
              text: 'open to and appreciates different perspectives',
            },
            {
              value: 'O_2_2',
              text: 'listens attentively without interrupting',
            },
            {
              value: 'O_2_3',
              text: 'perseveres through uncertainty and failures',
            },
            {
              value: 'O_2_4',
              text: "understands others' viewpoints, when different from own",
            },
            {
              value: 'O_2_5',
              text: "understands reasons for others' actions",
            },
            {
              value: 'O_3_1',
              text: 'provides constructive feedback to facilitate growth',
            },
            {
              value: 'O_3_2',
              text: 'empowers the team and believes in their capabilities',
            },
            {
              value: 'O_3_3',
              text: 'Is aware of and in control of his/her emotions',
            },
            {
              value: 'O_3_4',
              text: 'resolves conflict by bringing disagreements into the open',
            },
            {
              value: 'O_3_5',
              text: 'inspires the team to work towards change',
            },
            {
              value: 'O_4_1',
              text: 'sets clear goals and vision for the team to follow',
            },
            {
              value: 'O_4_2',
              text: 'adapts quickly to changing market conditions',
            },
            {
              value: 'O_4_3',
              text: 'continuously raises the bar',
            },
            {
              value: 'O_4_4',
              text: 'relentlessly drives to get the best from the teams',
            },
            {
              value: 'O_4_5',
              text: 'is undeterred by setbacks and course-corrects quickly',
            },
            {
              value: 'O_5_1',
              text: 'sees issues more than opportunities',
            },
            {
              value: 'O_5_2',
              text: 'encourages creative solutions to address routine challenges',
            },
            {
              value: 'O_5_3',
              text: 'strives to stay informed on the latest technological trends',
            },
            {
              value: 'O_5_4',
              text: 'skillfully applies technology(ies) to solve problems',
            },
            {
              value: 'O_5_5',
              text: 'leverages automation to improve process efficacy',
            },
            {
              value: 'O_6_1',
              text: 'consistently sticks to commitments',
            },
            {
              value: 'O_6_2',
              text: 'is open and honest in his/her communication',
            },
            {
              value: 'O_6_3',
              text: 'balances the what and how in driving results',
            },
            {
              value: 'O_6_4',
              text: 'values, respects, and recognizes contributions',
            },
            {
              value: 'O_6_5',
              text: 'expresses emotions and demonstrates vulnerability',
            },
          ],
          rowsOrder: 'random',
          isAllRowRequired: true,
        },
        {
          type: 'comment',
          name: 'O_8_A',
          title:
            'What specific behaviors should {otherName} continue demonstrating that have helped him/her to be effective?',
          hideNumber: true,
          isRequired: true,
        },
        {
          type: 'comment',
          name: 'O_8_B',
          title:
            'What specific behaviors should {otherName} stop demonstrating in order to be more effective?',
          hideNumber: true,
          isRequired: true,
        },
        {
          type: 'comment',
          name: 'O_8_C',
          title:
            'What specific behaviors should  {otherName} start demonstrating in order to be more effective?',
          hideNumber: true,
          isRequired: true,
        },
      ],
    },
  ],
  showPrevButton: false,
  showQuestionNumbers: 'off',
};

export default surveyJSON;
