import React from 'react';

const Footer = () => {
  return (
    <>
      <footer className='footer'>
        <p>ALTITUDES | All Rights Reserved</p>
      </footer>
    </>
  );
};

export default Footer;
