import React from 'react';

import altitudeslogo from '../img/altitudes-logo.png';

const Header = () => {
  return (
    <div className='header'>
      <div className='header__bar'>
        {/* <div className='header__bar--title'>
          <p>PERSPECTIVES</p></div> */}
        <img
          src={altitudeslogo}
          alt='altitudes-logo'
          className='header__bar--center-logo'
        />
      </div>
    </div>
  );
};

export default Header;
