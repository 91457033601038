import React, { useState } from 'react';
import * as Survey from 'survey-react';
import showdown from 'showdown';
import { Navigate } from 'react-router-dom';
import surveyJSON from '../survey/SurveyContent';
import axios from 'axios';

Survey.StylesManager.applyTheme('modernv2');

export function SurveyPage() {
  const [completed, setCompleted] = useState(false);

  var model = new Survey.Model(surveyJSON);

  var startTime, endTime;

  var converter = new showdown.Converter();
  model.onTextMarkdown.add(function (survey, options) {
    //convert the mardown text to html
    var str = converter.makeHtml(options.text);
    //remove root paragraphs <p></p>
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
    //set html
    options.html = str;
  });

  function doOnCurrentPageChanged(survey) {
    if (survey.currentPageNo === 1) {
      startTime = performance.now();
    }
  }

  const onCompleting = (result) => {
    let request = result.data;
    endTime = performance.now();
    var timeElapsed = Math.round((endTime - startTime) / 1000);
    // console.log(timeElapsed + ' seconds');
    request.timeElapsed = timeElapsed;
    // console.log(request);
    // console.log(JSON.stringify(request));

    axios
      .post('/persist', JSON.stringify(request), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .catch((error) => console.log(error));
  };

  const renderRedirect = () => {
    if (completed) {
      return <Navigate to='/complete' />;
    }
  };

  return (
    <div className='container'>
      {renderRedirect()}
      <Survey.Survey
        model={model}
        onCompleting={onCompleting}
        onCurrentPageChanged={doOnCurrentPageChanged}
        onComplete={() => setCompleted(!completed)}
      />
    </div>
  );
}
