import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Complete from './Complete';
import { SurveyPage } from './Survey';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route exact path='/' element={<SurveyPage />} />
        <Route exact path='/complete' element={<Complete />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
